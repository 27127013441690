<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          md="12"
        >
          <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center">
            {{ new Date().getFullYear() }} &copy; CSJLA - <v-icon @click="cerrarSesion()">
              mdi-space-invaders
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [],
    }),
    methods: {
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        if (logOut === true) {
          localStorage.removeItem('usuarioOdajup')
          this.$router.push('/')
        }
      },
    },
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
